@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.graph-font {
  font-family: 'Segoe UI Semibold', sans-serif;
}

.logo-container {
  display: flex;
  align-items: center; /* מיון הפריטים לאורך ציר Y */
}

.container {
  margin-left: 20px; /* מרווח שמאלי בין הלוגו לפאי צ'ארט */
}

.logo {
  margin-top: 25px;
  display: flex;
  padding-top:20%;
  justify-content: center;
  width: 88%; /* רוחב התמונה יהיה 100% מהמרחב הזמין */
  height: auto; /* הגובה יתאים בהתאם לרוחב כך שהתמונה לא תתעוקק */
}

.center-button-container {
  display: flex;
  flex-direction: column; /* נכוון את הכפתורים להיות בעמודה אחת */
  justify-content: center; /* ממרכז את הכפתורים באופן אנכי */
  align-items: center; /* ממרכז את הכפתורים באופן אופקי */
  height: 100vh; /* קבע את גובה התצוגה לגובה המקסימלי של החלון */
}

.cool-text {
  font-family: "CoolFont", sans-serif;
  font-size: 24px;
  color: #4285f4;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.target {
  margin-top: 20px;
}

.progress {
  width: 64%;
}

.charts {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.cool-header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center; /* ממרכז את הפריטים בקו אופקי */
  align-items: center; /* ממרכז את הפריטים בקו אנכי */
}

.logo img {
  width: 450px; /* גודל הלוגו */
  height: auto;
}

.card-container {
  display: flex;
  justify-content:center;
  width : 100%;
  padding-right: 4%;
  padding-left: 2%;
}

.custom-card {
  width: calc(50% - 20px); /* כדי לאפשר שלוש כרטיסיות בשורה, עם מרווח של 20 פיקסלים בין כל כרטיס */
  margin-bottom: 20px;
  background-color: #ded7d7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* סוף הכרטיס הימני, תחת הכרטיס התחתון, מימין */
  display:flex
}

.custom-header-card {
  margin-bottom: 20px;
  background-color: #ece9e9;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* סוף הכרטיס הימני, תחת הכרטיס התחתון, מימין */
  display:flex
}

.space {
  padding: 50px;
}

.winner {
  display:flex;
  justify-content:space-around;
}


.graph-container {
  padding: 15px; /* הוספת מרווח פנימי לכרטיס */
  background-color: #ededed; /* רקע אפור לכרטיס */
  border-radius: 10px; /* פינות מעוגלות לכרטיס */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* צל לכרטיס */
  width: calc(33.33% - 15px);
}


@media (max-width: 768px) {
  .custom-card {
    width: calc(50% - 20px); /* שני כרטיסיות בשורה עבור מסך קטן יותר */
  }
}

@media (max-width: 480px) {
  .custom-card {
    width: calc(100% - 20px); /* כרטיס אחד בשורה עבור מסך מובייל */
  }
}

.custom-card .charts {
  height: 300px; /* גובה קבוע לגרף */
}

.custom-card .charts .recharts-wrapper {
  border-radius: 10px; /* פינות מעוגלות לגרף */
}

.custom-card .charts .recharts-surface {
  border-radius: 10px; /* פינות מעוגלות לגרף */
}

.custom-card .charts .recharts-legend {
  margin-top: 20px; /* מרווח לגרף */
}

.custom-card .charts .recharts-legend-item {
  cursor: pointer; /* עיצוב סמלי הגרף */
}

.custom-card .charts .recharts-legend-item:hover {
  opacity: 0.7; /* עיצוב סמלי הגרף בעת העכבר */
}