/* סגנון לקוד נתון */
.lesson-students {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  direction: rtl;
}

.student-card {
  padding: 18px;
  border: 2px solid #8F734D;
  border-radius: 10px;
  background-color: #6D9B97;
  box-shadow: 0 0 10px rgba(109, 108, 108, 0.1);
  border-radius: 8px;
  width: 7%;
  transition: all 0.5s ease;
}

.selected {
  transform: scale(1.2);
}

.student-card h2 {
  text-align: center;
  margin-top: 0;
  color: aliceblue;
  font-family: 'Segoe UI Semibold', sans-serif;
  font-size: 13px;
  align-items: center;
}

.student-card p {
  margin: 0;
}

p {
  font-size: 10px;
  color: aliceblue;
  font-family: 'Segoe UI Light', sans-serif;
  text-align: center;
}