.centered-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
  
}

.centered-input label {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  width: 80px;
  display: inline-table;
}



.centered-input input[type="text"],
.centered-input input[type="number"] {
  width: 300px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  margin-bottom: 20px;
  background-color: #f2f2f2;
  outline: none;
}

.centered-input input[type="text"]:focus,
.centered-input input[type="number"]:focus {
  background-color: #e0e0e0;
}

.centered-input button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #4caf50;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.centered-input button:hover {
  background-color: #45a049;
}